/* You can add global styles to this file, and also import other style files */
@import '@mercadona/styles/sass/mixins';
@import '@mercadona/styles/sass/variables';

html,
body {
  height: 100%;
}

.w-0 {
  width: 0px;
}

.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}

// Heading fonts
.heading01-regular {
  @include font-heading01-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }

  &.text03 {
    color: var(--text03);
  }
}

.heading04-semibold {
  @include font-heading04-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }

  &.text03 {
    color: var(--text03);
  }
}

.heading05-semibold {
  @include font-heading05-semibold;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }

  &.text03 {
    color: var(--text03);
  }
}

.heading06-semibold {
  @include font-heading06-semibold;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }

  &.text03 {
    color: var(--text03);
  }
}

// Body fonts
.body01-semibold {
  @include font-body01-semibold;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }
  &.text03 {
    color: var(--text03);
  }
  &.text04 {
    color: var(--onSurface_High);
  }
}

.body02-semibold {
  @include font-body02-semibold;

  &.main01 {
    color: var(--main);
  }
  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }
  &.text03 {
    color: var(--text03);
  }
  &.text04 {
    color: var(--onSurface_High);
  }
  &.text05 {
    color: var(--onSurface_Medium);
  }
}

.body03-semibold {
  @include font-body03-semibold;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }
  &.text03 {
    color: var(--text03);
  }
  &.text04 {
    color: var(--onSurface_High);
  }
  &.text05 {
    color: var(--onSurface_Medium);
  }
}

.body02-regular {
  @include font-body02-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }
  &.text03 {
    color: var(--text03);
  }
  &.text04 {
    color: var(--onSurface_High);
  }

  &.text05 {
    color: var(--onSurface_Medium);
  }
}

.body01-regular {
  @include font-body01-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }
  &.text03 {
    color: var(--text03);
  }
  &.text04 {
    color: var(--onSurface_High);
  }
  &.text05 {
    color: var(--onSurface_Medium);
  }
  &.text06 {
    color: var(--onSurface_Low);
  }
}

.body03-regular {
  @include font-body03-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }
  &.text03 {
    color: var(--text03);
  }
  &.text04 {
    color: var(--onSurface_High);
  }
  &.text05 {
    color: var(--onSurface_Medium);
  }
}

.helper-italic {
  @include font-helper-italic;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }

  &.text03 {
    padding-left: 0.2rem !important;
    color: var(--text03);
  }

  &.text04 {
    color: var(--onSurface_High);
  }

  &.text05 {
    color: var(--onSurface_Medium);
  }
}

.helper-regular {
  @include font-helper-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }

  &.text03 {
    color: var(--text03);
  }

  &.text04 {
    color: var(--onSurface_High);
  }

  &.text05 {
    color: var(--onSurface_Medium);
  }
}

.heading03-regular {
  @include font-heading03-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }
  &.text03 {
    color: var(--text03);
  }
  &.text04 {
    color: var(--onSurface_High);
  }
  &.text05 {
    color: var(--onSurface_Medium);
  }
}

.heading04-regular {
  @include font-heading04-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }

  &.text03 {
    color: var(--text03);
  }

  &.text04 {
    color: var(--onSurface_High);
  }

  &.text05 {
    color: var(--onSurface_Medium);
  }
}

.heading06-regular {
  @include font-heading06-regular;

  &.text01 {
    color: var(--text01);
  }

  &.text02 {
    color: var(--text02);
  }
  &.text03 {
    color: var(--text03);
  }
  &.text04 {
    color: var(--onSurface_High);
  }
  &.text05 {
    color: var(--onSurface_Medium);
  }
  &.text06 {
    color: var(--onSurface_Low);
  }
}

.show-transition {
  -webkit-transition: all 0.85s ease-in-out;
  -moz-transition: all 0.85s ease-in-out;
  -o-transition: all 0.85s ease-in-out;
  transition: all 0.85s ease-in-out;
  -ms-transition: all 0.85s ease-in-out;
}

.input-clear-icon {
  color: var(--base30);
  cursor: pointer;
}

.search-icon {
  color: var(--main);
}

.modify-product-panel-class {
  .m-dialog-container {
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: 100%;

    m-dialog-card .m-dialog-card-content {
      display: flex;
      justify-content: center;
    }
  }
}

.new-need-structure-panel-class {
  .m-dialog-container {
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: 100%;

    m-dialog-card .m-dialog-card-content {
      display: flex;
      justify-content: center;
      height: calc(100% - 16px);
    }

    m-dialog-card m-list m-radio-group:not(.leafNode) m-radio-button > label > div.m-radio-button-content {
      visibility: hidden !important;
    }

    m-dialog-card m-list m-radio-group.leafNode ~ m-icon {
      color: var(--base) !important;
      display: none !important;
    }

    m-dialog-card m-list m-radio-group:not(.leafNode) ~ m-icon {
      color: var(--base) !important;
      cursor: pointer;
    }

    m-list-custom-item > div.m-list-item-container > div.m-list-item-header {
      cursor: unset;
      &:hover {
        background: var(--background);
      }
      &:has(m-radio-group.leafNode) {
        border-bottom: unset;
      }
    }

    m-list-custom-item.m-list-item-expanded > div.m-list-item-container > div.m-list-item-header {
      border-bottom: 1px solid var(--listDividerBackgroundColor);
    }

    m-list-custom-item > div.m-list-item-container > div.m-list-item-content {
      padding-left: 3.5rem !important;
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
  }
}

.geography-dialog-card
  > .m-dialog-card-container
  > .m-dialog-card-content
  .search-geography-form-container
  > m-form-field
  > .m-form-field-container
  > .m-form-field-subscript-wrapper {
  display: none;
}

.geography-dialog-card > .m-dialog-card-container > .m-dialog-card-content div > .geography-dialog-title-container {
  top: 1.5rem;
  margin-bottom: 20px;
}

.geography-dialog-card
  > .m-dialog-card-container
  > .m-dialog-card-content
  div
  > .geography-dialog-card-buttons-container {
  margin-top: 20px;

  & > span {
    margin-top: 0.8rem;
  }
}

.geography-dialog-card > .m-dialog-card-container > .m-dialog-card-content div > .geography-dialog-card-body {
  height: calc(100% - 70px);
  background: var(--background);
  translate: -5px 10px;

  .geography-keys-container {
    margin-top: 5px;
    max-height: 100%;
  }

  .geography-values-container {
    translate: 0px -3px;

    & > m-list > m-list-item > .m-list-item-container m-checkbox {
      translate: 5px;
    }
  }
}

.product-implementation-m-list-with-checkbox > m-list-item > .m-list-item-container {
  padding: 0.75rem 1rem 0.75rem 1rem !important;

  & > .m-list-item-content-container {
    order: 0;
  }
}

.new-need-structure-backdrop-class {
  background: var(--onSurface_ExtraLow);
  backdrop-filter: blur(8px);
}

.structure-dialog-container {
  background: var(--background);
}

.back-link-container > a > span.m-link-content {
  @include font-body02-semibold;
}

.cursor-pointer {
  cursor: pointer;
}

.vis-hidden {
  visibility: hidden;
  display: none !important;
}

.main-color {
  color: var(--main);
}

.alert-color {
  color: var(--alert);
}

.alert-success {
  color: var(--success60);
}

m-card.edit-product-variable-card > div > div.m-card-container {
  background: var(--background) !important;

  box-shadow: unset !important;
  &:hover {
    background: var(--background) !important;
  }

  & > .m-card-custom-container > .card-custom-content > .inner-variable-separator {
    border-bottom: 1px solid var(--onSurface_ExtraLow);
  }
}

.add-brand-dialog,
.add-model-dialog {
  .m-dialog-container {
    padding: 0rem;
  }
}

.edit-variables-cards-dialog .m-dialog-container {
  padding: 0rem;
  .m-dialog-card .m-dialog-card-container .m-dialog-card-content {
    max-height: calc(100% - 24px - 16px - 94.4px - 16px);
    overflow-y: scroll;
  }

  & > div > form > div > .first-option > div.icon-container > m-icon {
    position: relative;
    top: 0.5rem;
  }
}

.need-list-dialog .m-dialog-container {
  padding: 0;

  .m-dialog-card .m-dialog-card-container .m-dialog-card-content {
    max-height: calc(100% - 24px - 16px - 47.2px - 16px);
    display: flex;
  }
}

.cdk-overlay-container {
  z-index: 5000;
}

.max-height-100 {
  max-height: 100%;
}

.max-width-100 {
  max-width: 100%;
}

//MOVEMENT STATUSES

span.new-movement {
  color: var(--main); //green
}

span.modify-movement {
  color: var(--warning80); //yellow
}

span.delete-movement {
  color: var(--alert70); //red
}

//ARTICLE STATUSES

span.new-article {
  color: var(--main); //green
}

span.stock-article,
span.pending-article,
span.out-of-season-article {
  color: var(--warning80); //yellow
}

span.delete-article {
  color: var(--alert70); //red
}

.filter-img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

::-webkit-scrollbar {
  width: 0.45rem;
}
